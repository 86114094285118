<div class="col-md-6">
    <div class='row special-skill' data-skillid='{{id}}'>
        {{#ifTxtContains text "endast erfarenhet"}}
        <div class='col-md-7'>
            <label class="header">{{text}}</label>
        </div>
        {{else}}
        <div class='col-md-7'>
            <label class="header">{{text}}</label>
            <label class="small">
                <input name='chkST' type='checkbox' class='special-skill-chkbx ignore-serialization' {{#if st}} checked{{/if}} />
                {{LocalizationRes.DuringST}}
            </label>
        </div>
        <div class='col-md-4 {{#unless st}}inactive{{/unless}}'>
            <div class='form-group div-planned'>
                <label for='txtPlanned' class="small">
                    {{LocalizationRes.Planned_ready}}
                </label>
                <input name='txtPlanned' class='form-control jcf-ignore txt-planned ignore-serialization' type='text' runat='server' {{#unless st}} disabled='True' {{/unless}} value="{{dReadyDateST}}" data-validation="required date" data-validation-format="yyyy-mm-dd"
                       data-validation-error-msg="Du har inte angett ett korrekt datum (yyyy-mm-dd)" />
            </div>
        </div>
        <div class='col-md-4'>
            <div class='form-group div-done {{#if st}}inactive{{/if}}'>
                <label for='txtDone' class="small">
                    {{LocalizationRes.Issued}}
                </label>
                <input name='txtDone' class='form-control jcf-ignore txt-done ignore-serialization' type='text' runat='server' {{#if st}} disabled='True' {{/if}} value="{{dLicensedYear}}" data-validation="required date" data-validation-format="yyyy" />
            </div>
        </div>
        {{/ifTxtContains}}
        <div class='trash'><a href='#' class='remove-skill'><span class='glyphicon glyphicon-trash' aria-hidden='true'></span></a></div>
    </div>
</div>