<div class="col-md-6">
    <div class='row special-skill' data-skillid='{{id}}'>
        <div class="col-md-12">
            <div class="row">
                <div class='col-md-7'>
                    <label class="header">{{text}}</label>
                </div>
                <div class='col-md-4'>

                </div>
            </div>
            <div class="row">
                {{#if bLevels}}
                <div class="col-md-7">

                    <label for='pkLevelID' class="small">
                        {{LocalizationRes.StateHighestLevel}}
                    </label>

                </div>
                {{/if}}
                <div class="col-md-4">
                    {{#if customer}}
                    <label for='txtDone' class="small">
                        {{LocalizationRes.Issued}}
                    </label>
                    {{/if}}
                </div>
            </div>
            <div class="row">
                {{#if bLevels}}
                <div class="col-md-7">

                    <select name="pkLevelID" class="form-control jcf-ignore select2 pk-level-id" data-validation="required">
                        {{#select selectedSkillLevel}}
                        <option value="">Välj</option>
                        {{#each levels key="@item" as |level|}}
                        <option value={{level.pkSpecialSkillLevelId}}>{{level.sName}}</option>
                        {{/each}}
                        {{/select}}
                    </select>

                </div>
                {{/if}}
                
                <div class="col-md-4">
                    {{#if customer}}
                    <div class='form-group div-done'>
                        <input name='txtDone' class='form-control jcf-ignore txt-done ignore-serialization' type='text' runat='server' value="{{dLicensedYear}}" data-validation="required date" data-validation-format="yyyy" />
                    </div>
                    {{/if}}
                </div>
                
            </div>
        </div>

        <div class='trash'><a href='#' class='remove-skill'><span class='glyphicon glyphicon-trash' aria-hidden='true'></span></a></div>
    </div>
</div>